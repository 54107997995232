




























import { defineComponent, PropType, toRef } from '@vue/composition-api'
import { StyleConfig } from '@/composables/useStyling'

export type Props = {
  title: string
  styles: StyleConfig
}

export default defineComponent<Props>({
  name: 'TitleCard',
  props: {
    title: { type: String, required: true },
    styles: { type: Object as PropType<StyleConfig>, required: true },
  },
  setup() {
    function onTitleCardClick() {
      window.open('https://quantifycrypto.com', '_blank')
    }

    return {
      onTitleCardClick,
    }
  },
})
