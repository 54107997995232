var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"market-card",style:({
    color: _vm.styles.baseTextColor.value,
    background: _vm.styles.intensityColor(_vm.ptcChange),
    border: _vm.styles.baseBorder.value,
  }),on:{"click":_vm.onTitleCardClick}},[_c('div',{staticClass:"logo-wrapper"},[_c('svg',{staticClass:"qc-logo",attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"currentColor","d":"M3,14L3.5,14.07L8.07,9.5C7.89,8.85 8.06,8.11 8.59,7.59C9.37,6.8 10.63,6.8 11.41,7.59C11.94,8.11 12.11,8.85 11.93,9.5L14.5,12.07L15,12C15.18,12 15.35,12 15.5,12.07L19.07,8.5C19,8.35 19,8.18 19,8A2,2 0 0,1 21,6A2,2 0 0,1 23,8A2,2 0 0,1 21,10C20.82,10 20.65,10 20.5,9.93L16.93,13.5C17,13.65 17,13.82 17,14A2,2 0 0,1 15,16A2,2 0 0,1 13,14L13.07,13.5L10.5,10.93C10.18,11 9.82,11 9.5,10.93L4.93,15.5L5,16A2,2 0 0,1 3,18A2,2 0 0,1 1,16A2,2 0 0,1 3,14Z"}})])]),_c('div',{staticClass:"market-text-wrapper"},[_c('div',{staticClass:"market-title-text text-underline-offset"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"market-subtitle-text"},[_vm._v(_vm._s(_vm.subTitle))])]),_c('div',{staticStyle:{"text-align":"right","width":"100%","padding-right":"5px"}},[_c('div',{staticStyle:{"margin-top":"6px"}},[_vm._v(_vm._s((_vm.ptcChange * 100).toFixed(2))+"%")]),_c('svg',{class:_vm.ptcChange >= 0 ? 'qc-indicator-up' : 'qc-indicator-down',style:({
        width: ' 34px',
        height: ' 34px',
        marginTop: '-10px',
        color: _vm.styles.ptcChangeTextColor(_vm.ptcChange),
      }),attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"currentColor","d":_vm.ptcChange >= 0 ? 'M7,15L12,10L17,15H7Z' : 'M7,10L12,15L17,10H7Z'}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }