import Vue from 'vue'
// import { createApp } from '@vue/composition-api'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGtag from 'vue-gtag'
import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)

/**********Use for Production build************/
import vueCustomElement from 'vue-custom-element'
Vue.use(vueCustomElement)

Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
Vue.use(VueAxios, axios)
Vue.use(VueGtag, { config: { id: process.env.VUE_APP_GTAG_ID } })

/************Use for Production build************/
Vue.customElement('qc-price-ticker-widget', App, {
  shadow: true,
  beforeCreateVueInstance(root: any) {
    const rootNode: ShadowRoot = root.el.getRootNode()

    if (rootNode instanceof ShadowRoot) {
      // console.debug('shadowRoot found! Using as root node ')
      root.shadowRoot = rootNode
    } else {
      // console.debug('shadowRoot not found! Using document head ')
      root.shadowRoot = document.head
    }

    return root
  },
})

// /*****Use for development****/
// createApp(App).mount('#qc-marquee')
// /****************************/
// // import Vue from 'vue'
// // import { createApp } from '@vue/composition-api'
// // import VueCompositionAPI from '@vue/composition-api'
// // import App from './App.vue'
// //
// // Vue.use(VueCompositionAPI)
// //
// // createApp(App).mount('#app')
