













































import { defineComponent, PropType, toRef } from '@vue/composition-api'
import { StyleConfig } from '@/composables/useStyling'

export type Props = {
  title: string
  subTitle: string
  ptcChange: number
  styles: StyleConfig
}

export default defineComponent<Props>({
  name: 'MarketCard',
  props: {
    title: { type: String, required: true },
    subTitle: { type: String, required: true },
    ptcChange: { type: Number, default: 0 },
    styles: { type: Object as PropType<StyleConfig>, required: true },
  },
  setup() {
    function onTitleCardClick() {
      window.open(`${process.env.VUE_APP_BASE_URL}/markets`, '_blank')
    }

    return {
      onTitleCardClick,
    }
  },
})
