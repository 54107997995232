import { ModeOptions, ThemeOptions } from '@/types'
import { computed, ComputedRef, Ref } from '@vue/composition-api'

export interface StyleConfig {
  backgroundColor: ComputedRef<string>
  baseTextColor: ComputedRef<string>
  baseBorder: ComputedRef<string>
  ptcChangeTextColor: (x: number) => string
  intensityColor: (x: number) => string
}

export default function (
  theme: Ref<ThemeOptions>,
  mode: Ref<ModeOptions>,
  bg: Ref<string>
): StyleConfig {
  const backgroundColor = computed(() => bg.value)

  const baseTextColor = computed(() =>
    theme.value === ('dark' as ThemeOptions) ? '#FFFFFF' : '#000000'
  )

  const baseBorder = computed(() =>
    theme.value === ('dark' as ThemeOptions)
      ? '1px solid #2f2f2f'
      : '1px solid #E0E0E0'
  )

  function ptcChangeTextColor(x: number): string {
    if (mode.value === 'custom') {
      if (x < 0) {
        return '#E53935'
      } else {
        return '#26A69A'
      }
    } else {
      if (theme.value === 'dark') {
        return '#fff'
      } else {
        return '#000'
      }
    }
  }

  function intensityColor(x: number): string {
    if (mode.value == 'custom') {
      return backgroundColor.value
    } else {
      if (x * 100 > 0 && x * 100 <= 1) {
        return '#71c175'
      } else if (x * 100 > 1 && x * 100 <= 2.5) {
        return '#4eb153'
      } else if (x * 100 > 2.5 && x * 100 <= 5) {
        return '#3e8e42'
      } else if (x * 100 > 5) {
        return '#2f6a32'
      } else if (x * 100 <= 0 && x * 100 >= -1) {
        return '#ff8080'
      } else if (x * 100 < -1 && x * 100 >= -2.5) {
        return '#ff4d4d'
      } else if (x * 100 < -2.5 && x * 100 >= -5) {
        return '#ff1a1a'
      } else if (x * 100 < -5) {
        return '#e60000'
      }
      return ''
    }
  }

  return {
    backgroundColor,
    baseTextColor,
    baseBorder,
    ptcChangeTextColor,
    intensityColor,
  }
}
