

















































import axios from 'axios'
import {
  defineComponent,
  PropType,
  ref,
  toRef,
  onMounted,
  computed,
} from '@vue/composition-api'

import {
  ModeOptions,
  CurrencyOptions,
  ThemeOptions,
  PriceTickerData,
  MarketIndexData,
} from '@/types'

import TitleCard from '@/components/cards/TitleCard.vue'
import TickerCard from '@/components/cards/TickerCard.vue'
import MarketCard from '@/components/cards/MarketCard.vue'
import useStyling from '@/composables/useStyling'

export type Props = {
  mode: ModeOptions
  topCoins: boolean
  gainersAndLosers: boolean
  bg: string
  theme: ThemeOptions
  currency: CurrencyOptions
  duration: string
}

export default defineComponent<Props>({
  name: 'App',
  components: { MarketCard, TickerCard, TitleCard },
  props: {
    mode: { type: String as PropType<ModeOptions>, default: 'custom' },
    topCoins: { type: Boolean, default: true },
    gainersAndLosers: { type: Boolean, default: true },
    bg: { type: String, default: '#1e1e1e' },
    theme: { type: String as PropType<ThemeOptions>, default: 'dark' },
    currency: { type: String as PropType<CurrencyOptions>, default: 'USD' },
    duration: { type: String, default: '200s' },
  },
  setup(props) {
    const loading = ref(true)
    const isPaused = ref(false)

    const currentTheme = toRef(props, 'theme')
    const currentMode = toRef(props, 'mode')
    const currentBg = toRef(props, 'bg')
    const currentDuration = toRef(props, 'duration')

    const styles = useStyling(currentTheme, currentMode, currentBg)

    const marqueePlayState = computed(() =>
      isPaused.value ? 'paused' : 'running'
    )

    const modeModel = toRef(props, 'mode')
    const topCoinsModel = toRef(props, 'topCoins')
    const gainersAndLosersModel = toRef(props, 'gainersAndLosers')
    const themeModel = toRef(props, 'theme')
    const currencyModel = toRef(props, 'currency')

    const priceTickerData = ref<PriceTickerData>()
    const marketData = computed<MarketIndexData[]>(() => [
      {
        name: 'QC-100',
        index: 'Index',
        ptc_change:
          priceTickerData.value?.data.qc_100_24h_ptc_change_index ?? 0,
      },
    ])

    const tickerData = computed(() => [
      {
        title: 'Top 20 Coins',
        data: priceTickerData.value?.data.top_20_coins ?? [],
      },
      {
        title: 'Top 10 Gainers',
        data: priceTickerData.value?.data.top_10_gainers ?? [],
      },
      {
        title: 'Top 10 Losers',
        data: priceTickerData.value?.data.top_10_losers ?? [],
      },
    ])

    async function fetchData() {
      const { data } = await axios.get(`/api/v1.0/widgets/price-tickers`, {
        params: {
          top_gainers_losers: gainersAndLosersModel.value,
          top_20_coins: topCoinsModel.value,
          currency: currencyModel.value,
        },
      })
      priceTickerData.value = data
    }

    onMounted(async () => {
      await fetchData()
      loading.value = false
    })
    return {
      loading,
      isPaused,
      modeModel,
      themeModel,
      currencyModel,
      tickerData,
      marketData,
      marqueePlayState,
      currentDuration,
      styles,
    }
  },
})
