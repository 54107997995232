



















































import {
  computed,
  defineComponent,
  PropType,
  toRef,
} from '@vue/composition-api'
import { StyleConfig } from '@/composables/useStyling'
import { CurrencyOptions, ModeOptions } from '@/types'

export type Props = {
  mode: ModeOptions
  styles: StyleConfig
  currencyCode: CurrencyOptions
  locale: string

  qcKey: string
  symbolName: string
  priceUsd: number
  price24h: number
}

export default defineComponent<Props>({
  name: 'TickerCard',
  props: {
    mode: { type: String as PropType<ModeOptions>, default: 'default' },
    styles: { type: Object as PropType<StyleConfig>, required: true },
    currencyCode: { type: String as PropType<CurrencyOptions>, default: 'USD' },
    locale: { type: String, default: 'en' },
    priceUsd: { type: Number, default: 0 },
    price24h: { type: Number, default: 0 },

    qcKey: { type: String, default: '' },
    symbolName: { type: String, default: '' },
  },
  setup(props) {
    const modeModel = toRef(props, 'mode')
    const currencyModel = computed(() => props.currencyCode)
    const localeModel = computed(() => props.locale)

    const hoverClass = computed(() =>
      modeModel.value === ('default' as ModeOptions)
        ? 'default-mode'
        : 'custom-mode'
    )

    function priceFormatter(val: number) {
      let maxDigits

      if (val >= 10) {
        maxDigits = 2
      } else if (val >= 0.0001) {
        maxDigits = 4
      } else if (val >= 0.00001) {
        maxDigits = 6
      } else if (val >= 0.000001) {
        maxDigits = 8
      } else {
        maxDigits = 11
      }

      return new Intl.NumberFormat(localeModel.value, {
        currency: currencyModel.value,
        minimumFractionDigits: 2,
        maximumFractionDigits: maxDigits,
      }).format(val)
    }

    function price24hFormatter(price24h: number) {
      return (price24h * 100).toFixed(2) + '%'
    }

    function onCardClick() {
      window.open(`https://quantifycrypto.com/coins/${props.qcKey}`, '_blank')
    }

    return {
      hoverClass,

      priceFormatter,
      price24hFormatter,
      onCardClick,
    }
  },
})
